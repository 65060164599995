import React, { useEffect, useRef } from "react";
import lottie, { type AnimationItem } from "lottie-web";

interface LottieProps {
    animationData: object;
    loop?: boolean;
    autoplay?: boolean;
    style?: React.CSSProperties;
    playOnHover?: boolean;
    className?: string;
    setSpeed?: number;
    reverseOnLeave?: boolean;
}

const Lottie: React.FC<LottieProps> = props => {
    const container = useRef<HTMLDivElement | null>(null);
    let animation: AnimationItem | undefined;
    let currentFrame: number = 0;

    useEffect(() => {
        if (container.current) {
            animation = lottie.loadAnimation({
                container: container.current,
                renderer: "svg",
                loop: props.loop || false,
                autoplay: props.autoplay ?? true,
                animationData: props.animationData
            });

            const { setSpeed, reverseOnLeave } = props;

            if (setSpeed) {
                animation.setSpeed(setSpeed);
            }

            if (props.playOnHover) {
                const handleMouseEnter = () => {
                    animation?.setDirection(1);
                    animation?.goToAndPlay(0, true);
                };

                const handleMouseLeave = () => {
                    currentFrame = animation?.currentFrame || 0;

                    if (reverseOnLeave) {
                        if (animation) {
                            animation.setDirection(-1);
                            animation.goToAndPlay(currentFrame, true);
                        }
                    } else {
                        animation?.stop();
                    }
                };

                const handleReverseComplete = () => {
                    if (animation?.currentFrame === 0) {
                        animation.setDirection(1);
                    }
                };

                container.current.addEventListener(
                    "mouseenter",
                    handleMouseEnter
                );
                container.current.addEventListener(
                    "mouseleave",
                    handleMouseLeave
                );

                if (reverseOnLeave) {
                    animation.addEventListener(
                        "complete",
                        handleReverseComplete
                    );
                }

                return () => {
                    container.current?.removeEventListener(
                        "mouseenter",
                        handleMouseEnter
                    );
                    container.current?.removeEventListener(
                        "mouseleave",
                        handleMouseLeave
                    );

                    if (reverseOnLeave) {
                        animation?.removeEventListener(
                            "complete",
                            handleReverseComplete
                        );
                    }

                    animation?.destroy();
                };
            }

            return () => {
                animation?.destroy();
            };
        }
    }, [props]);

    return (
        <div
            ref={container}
            style={props.style}
            className={props.className}
        ></div>
    );
};

export default Lottie;
